import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { CrudService } from "src/app/services/crud.service";

@Component({
  selector: "app-questionnaire-details",
  templateUrl: "./questionnaire-details.component.html",
  styleUrls: ["./questionnaire-details.component.css"],
})
export class QuestionnaireDetailsComponent implements OnInit {
  spinner: boolean = false;
  videoId: any;
  limit: number = 3;
  page: number = 1;
  questionnaireId: any;
  questionnaireDetails: any[] = [];
  totalSubmittedQuestionnaires: any;

  constructor(
    private crudService: CrudService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
  ) {
    if(this.data && this.data.videoId) {
      this.videoId = this.data?.videoId
      this.questionnaireId = this.data?.questionnaireId
    } else {
      this.videoId = this.activatedRoute.snapshot.queryParamMap.get("videoId");
      this.questionnaireId =
        this.activatedRoute.snapshot.queryParamMap.get("questionnaireId");
    }
  }

    ngOnInit(): void {
      
      this.getQuestionnaireResultDetails(this.page, this.limit);
      this.getNoOfSubmittedQuestionnaires()
    }

    onScrollTab(): void {
      ++this.page;
      this.getQuestionnaireResultDetails(this.page, this.limit)
    }

    getQuestionnaireResultDetails(page:number, limit:number) {

      this.spinner = true

      let data = {
        videoId : this.videoId,
        questionnaireId: this.questionnaireId,
        page,
        limit
      }
      this.crudService
        .getQuestionnaireResultDetails(data)
        .subscribe((res: any) => {
          this.questionnaireDetails = [...this.questionnaireDetails, ...res.data];
          this.spinner = false;
        });
    }

    getNoOfSubmittedQuestionnaires() {

      this.spinner = true

      let data = {
        videoId : this.videoId,
        questionnaireId: this.questionnaireId
      }
      this.crudService
        .getNoOfSubmittedQuestionnaires(data)
        .subscribe((res: any) => {
          this.totalSubmittedQuestionnaires = res.data.noOfSubmittedQuestionnaires
          this.spinner = false;
        });
    }
}
