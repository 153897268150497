// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  width: 100%;
}

.main-body {
  padding: 0 15px 0 15px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.login {
  padding-left: 320px;
}

.fixed-icon {
  position: fixed;
  bottom: 20px;
  right: 19px;
  z-index: 9999;
  padding: 2px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 2px solid #6c2fbb;
  transition: all .15s ease;
  width: 45px;
  height: 45px;
}

.fixed-icon img{
  width: 100%;
  height: 100%;
}

.fixed-icon:hover {
  transform: scale(1.1);
}

.mini-sidebar-body {
  padding-left: 100px;
}

.guest {
  margin: 0 150px;
}

.anchor-link {
  background: #e1e1f9;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #6438b4;
  padding: 5px 15px;
  font-weight: bold;
  font-size: 14px;
}

.anchor-link:hover {
  color: #6c2fbb;
}

.close-icon {
  position: absolute;
  right: 16px;
  top: 10px;
}

@media (max-width: 991px) {
  .main-body {
    padding-left: 15px;
  }

  .guest {
    margin: 0 50px;
  }
}

@media (max-width: 425px) {
  .main-body {
    padding: 10px;
  }

  .guest {
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/layout/layout.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n}\n\n.main-body {\n  padding: 0 15px 0 15px;\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n}\n\n.login {\n  padding-left: 320px;\n}\n\n.fixed-icon {\n  position: fixed;\n  bottom: 20px;\n  right: 19px;\n  z-index: 9999;\n  padding: 2px;\n  border-radius: 50%;\n  background: white;\n  cursor: pointer;\n  border: 2px solid #6c2fbb;\n  transition: all .15s ease;\n  width: 45px;\n  height: 45px;\n}\n\n.fixed-icon img{\n  width: 100%;\n  height: 100%;\n}\n\n.fixed-icon:hover {\n  transform: scale(1.1);\n}\n\n.mini-sidebar-body {\n  padding-left: 100px;\n}\n\n.guest {\n  margin: 0 150px;\n}\n\n.anchor-link {\n  background: #e1e1f9;\n  border-radius: 20px;\n  border: none;\n  outline: none;\n  color: #6438b4;\n  padding: 5px 15px;\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.anchor-link:hover {\n  color: #6c2fbb;\n}\n\n.close-icon {\n  position: absolute;\n  right: 16px;\n  top: 10px;\n}\n\n@media (max-width: 991px) {\n  .main-body {\n    padding-left: 15px;\n  }\n\n  .guest {\n    margin: 0 50px;\n  }\n}\n\n@media (max-width: 425px) {\n  .main-body {\n    padding: 10px;\n  }\n\n  .guest {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
