// tslint:disable:max-line-length
import { environment } from 'src/environments/environment';

export const defaultStepOptions = {
    canClickTarget: false,
    highlightClass: 'highlight',
    cancelIcon: {
        enabled: true
    },
    beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
        });
    }
}

export const steps = [
    {
        id: 'welcome-message-1',
        buttons: [
            // {
            //     classes: 'btn btn-sm mr-auto',
            //     text: 'Back',
            //     type: 'back',
            // secondary:true
            // },
            {
                classes: 'btn btn-sm m-auto',
                text: 'Let\'s start',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Welcome to FreeFuse!',
        text: '',
    },
    {
        id: 'create-new-video-2',
        attachTo: {
            element: '#create-new-video',
            on: 'bottom-start'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Create new video',
        text: '<p>To create content, click on this button to access the FreeFuse creation interface. Here, you’ll be able to use the FreeFuse auto-editor and free board editing tools. </p> <p>To get started, feel free to upload your videos and then get back to the tour. </p> <p>We recently introduce the AI Video Creator that it will generate a video tree based on a topic of your choice. Don\'t forget to check it out! </p>',
    },
    {
        id: 'videos-3',
        attachTo: {
            element: '#my-videos-link',
            on: 'bottom-end'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Content Trees',
        text: 'On the content trees tab, you can find all of your created FreeFuse video trees. You can view, edit, and check out your video analytics.',
    },
    {
        id: 'Groups-4',
        attachTo: {
            element: '#my-groups-link',
            on: 'bottom-end'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Groups',
        text: '<p>You can create groups and invite colleagues, friends, or fellow subject matter enthusiasts. Build your audience or share content within your company. The groups section is versatile for whatever you’re trying to do.</p> <p>Dont’t forget to visit <a href="' + environment.currentDomain + '/my-group/' + environment.FreeFuse_GroupId + '">FreeFuse Community Space</a> where you can see Tips & Tricks, Example Content for different use cases, and more. You can reach out to our admin team and ask them any questions on the Discussion Board. Use the @ sign to tag one of them and write a question or comment.</p>',
    },
    {
        id: 'library-5',
        attachTo: {
            element: '#library-link',
            on: 'bottom-end'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Library',
        text: 'In your library, you’ll find videos you’ve uploaded to FreeFuse along with individual clips which have been created by the auto-editor. You’ll also be able to upload and edit videos here too.',
    },
    {
        id: 'saved-nodes-6',
        attachTo: {
            element: '#saved-nodes-link',
            on: 'bottom-end'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Done',
                type: 'next',
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Saved nodes',
        text: 'In the Saved nodes tab, you’ll find all collections of saved nodes that you’ve gathered from FreeFuse videos you’ve watched.',
    }
];
export const videoPageSteps = [
    {
        id: 'tree-content-btn',
        attachTo: {
            element: '#tree-content-btn',
            on: 'right'
        },
        canClickTarget: true,
        buttons: [
            // {
            //     classes: 'btn btn-sm mr-auto',
            //     text: 'Back',
            //     type: 'back',
            // secondary:true
            // },
            {
                classes: 'btn btn-sm ml-auto',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        title: 'Tree Content',
        text: 'Click here to view the tree',
    },
    {
        id: 'tree-section',
        attachTo: {
            element: '#tree-section',
            on: 'top'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Next',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: true,
        title: 'Tree Content',
        text: `For a different viewing experience, you can also navigate the video in its tree format by selecting the tree content button or navigating to the tree below. <br><br> You can click on any node within the tree to watch that video. You can jump from one path to another and watch the content in any order you’d like using this navigation.`,
    },
    {
        id: 'your-decisions',
        attachTo: {
            element: '#your-decisions',
            on: 'top-start'
        },
        buttons: [
            {
                classes: 'btn btn-sm mr-auto',
                text: 'Back',
                type: 'back',
                secondary: true
            },
            {
                classes: 'btn btn-sm',
                text: 'Done',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: true,
        title: 'Decisions',
        text: 'Your choices will be displayed for you and the most popular paths through the content that other users have gone through',
    },
];

export const lexieChatBotSuggestions = [
    {
        route: '/upload-videos',
        options: [
            {
                content: 'Storyboard',
                description: 'I\'d like to create a storyboard outlining the paths and choices. I will upload the videos later.',
                questions: [{
                  q1: 'What would you like to do next?',
                  values: ['Help me to generate storyboard']
                }]
            },
            // {
            //     content: 'Freeboard',
            //     description: 'I know what I\'m trying to make. I\'ll create my own video tree.',
            //     questions: [{
            //         q1: 'What would you like to do next?',
            //         values: ['Help me to generate storyboard']
            //     }]
            // },
            // {
            //     content: 'Template',
            //     description: 'I\'m not sure how to organize my content, I can use some prebuilt templates.',
            //     questions: [{
            //         q1: 'What would you like to do next?',
            //         values: ['Help me to generate storyboard']
            //     }]
            // },
            {
                content: 'AI Auto Editor',
                description: 'I\'d like to create a multipath media from my existing video.',
                questions: [{
                    q1: 'What would you like to do next?',
                    values: ['Help me to generate storyboard']
                }]
            }
        ]
    },
    {
        route: '/onboarding',
        options: [
            {
                content: 'Onboarding',
                description: 'You are currently enrolled in Stripe Connected Account.',
                questions: [{
                    q1: 'What would you like to do next?',
                    values: ['Help me to generate storyboard']
                }]
            },
            {
                content: 'Create Your Content',
                description: 'Now that you have completed Stripe onboarding, it is time to create your contents.',
                questions: [{
                    q1: 'What would you like to do next?',
                    values: ['Help me to generate storyboard']
                }]
            },
            {
                content: 'Plans & Pricing',
                description: 'Customize your subscription plans with unique pricing and offerings. Once complete, you can preview your custom Plans & Pricing page.',
                questions: [{
                    q1: 'What would you like to do next?',
                    values: ['Help me to generate storyboard']
                }]
            },
            {
                content: 'Build Your Community',
                description: 'Great work building out your Plans & Pricing! You are now ready to invite community members.',
                questions: [{
                    q1: 'What would you like to do next?',
                    values: ['Help me to generate storyboard']
                }]
            }
        ]
    }
];

export const predefinedTopicsForAIVideoCreator = [
    {
        id: 1,
        value: "Troubleshooting common VR headset issues"
    },
    {
        id: 2,
        value: "Technical Interview Preparation"
    },
    {
        id: 3,
        value: "Generative AI explanation"
    },
    {
        id: 4,
        value: "How to train a puppy"
    },

];

export const lexieChatBotAccess = [
    '/upload-videos',
    '/onboarding'
]

export default {steps, videoPageSteps, defaultStepOptions};
