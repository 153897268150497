// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form > h6 {
  font-size: 15px;
  color: #3c4858;
  margin-bottom: 15px;
  font-weight: 600;
}
.signup-form .pwd-info {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
}
.signup-form .pwd-info > img {
  width: 15px;
  height: 15px;
}
.input-mess-info {
  border: none;
  box-shadow: 0px 0px 10px 2px #dad8d8;
  font-size: 11px;
  color: #3c4858;
}
.input-mess-info .popover-body {
  padding: 15px;
}
.input-mess-info .popover-body p {
  margin-bottom: 5px;
}

.btn-show-password {
  position: absolute;
  right: 0;
  top: 0;
}

.selectpicker{
  font-size: 14px !important;
}
.h6,
h6 {
  font-size: 1rem;
}

.tc-pp-text {
  color: #aeaeae;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 0px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/beforeLogin/signup/signup.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,oCAAoC;EACpC,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,0BAA0B;AAC5B;AACA;;EAEE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".signup-form > h6 {\r\n  font-size: 15px;\r\n  color: #3c4858;\r\n  margin-bottom: 15px;\r\n  font-weight: 600;\r\n}\r\n.signup-form .pwd-info {\r\n  position: absolute;\r\n  right: 15px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  font-size: 12px;\r\n}\r\n.signup-form .pwd-info > img {\r\n  width: 15px;\r\n  height: 15px;\r\n}\r\n.input-mess-info {\r\n  border: none;\r\n  box-shadow: 0px 0px 10px 2px #dad8d8;\r\n  font-size: 11px;\r\n  color: #3c4858;\r\n}\r\n.input-mess-info .popover-body {\r\n  padding: 15px;\r\n}\r\n.input-mess-info .popover-body p {\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.btn-show-password {\r\n  position: absolute;\r\n  right: 0;\r\n  top: 0;\r\n}\r\n\r\n.selectpicker{\r\n  font-size: 14px !important;\r\n}\r\n.h6,\r\nh6 {\r\n  font-size: 1rem;\r\n}\r\n\r\n.tc-pp-text {\r\n  color: #aeaeae;\r\n  font-size: 12px;\r\n  margin-top: 22px;\r\n  margin-bottom: 0px;\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
