import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
// import Chart from 'chart.js';
import { Chart } from "chart.js/auto";
import { CrudService } from "src/app/services/crud.service";
import { QuestionnaireDetailsComponent } from "../questionnaire-details/questionnaire-details.component";

@Component({
  selector: "app-questionnaires-dashboard",
  templateUrl: "./questionnaires-dashboard.component.html",
  styleUrls: ["./questionnaires-dashboard.component.css"],
})
export class QuestionnairesDashboardComponent implements OnInit {
  spinner: any = false;
  chart: any;
  videoId: string = "";
  mainVideoDetail: any;
  questionnaireResult: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private crudService: CrudService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
  ) {

    if (this.data && this.data.videoId) {
      this.videoId = this.data?.videoId;
    } else {
      this.route.params.subscribe((param) => {
        this.videoId = param["id"];
      });
    }
  }

  ngOnInit(): void {
    this.getMainVideoInfo();
    this.spinner = true;
  }

  createChart(questionnaire: any) {
    questionnaire.questions.forEach(async (question: any) => {
      const chartId = `chartForAnswer_${question._id}`;

      let labels: any[] = [];
      let values: any[] = [];

      if (question.questionType === "multipleChoice") {
        labels = question.answers.map(
          (answer: any, index: number) =>
            `${index + 1}${
              index === 0
                ? "st"
                : index === 1
                ? "nd"
                : index === 2
                ? "rd"
                : "th"
            } Answer`
        );
        values = question.answers.map((j: any) =>
          this.getAverageForMultipleChoice(
            questionnaire?.totalSubmittedQuestionnaire,
            j.totalSubmittedAnswers
          )
        );
      } else {
        labels = ["Answer", "No Answer"];
        values.push(
          this.getAverageForFreeForm(
            question.totalNonBlankAnswers,
            question.totalSubmittedAnswers
          )
        );
        values.push(
          this.getAverageForFreeForm(
            question.totalBlankAnswers,
            question.totalSubmittedAnswers
          )
        );
      }

      // Destroy the existing chart if it exists
      // const existingChart = Chart.getChart(chartId);
      // console.log('2 ->', existingChart)
      // if (existingChart) {
      //   existingChart.destroy();
      // }

      // Create the new chart
      const chart = new Chart(chartId, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Answers",
              data: values,
              backgroundColor: ["#EB2828", "#119D17", "#2C73FE", "#EB7017", "#6C2FBB"],
              barThickness: 30,
              //borderRadius: 8,
            },
          ],
        },
        options: {
          aspectRatio: 1.5,
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false,
              },
              // ticks: {
              //   autoSkip: false,
              //   maxRotation: 0,
              //   minRotation: 0,
              // },
              ticks: {
                callback: function (index) {
                  if (/\s/.test(labels[index])) {
                    return labels[index].split(" ");
                  } else {
                    return labels[index];
                  }
                },
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    });
  }

  goToDetail(videoId: any, questionnaireId: any) {

    if (this.data && this.data?.videoId) {
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(QuestionnaireDetailsComponent, {
        data: {
          videoId: videoId,
          questionnaireId: questionnaireId
        },
        panelClass: 'questionnairesDashboard',
        minWidth: '50vw'
      });

    } else {
      this.router.navigate(["/questionnaires-dashboard-details"], {
        queryParams: { videoId: videoId, questionnaireId: questionnaireId },
      });
    }

  }

  getMainVideoInfo() {
    this.spinner = true;
    this.crudService.getVideoInfov2(this.videoId).subscribe((res) => {
      this.mainVideoDetail = res.data?.videoinfo;
      this.getQuestionnaireResultAnalytics();
    });
  }

  waitForElement(
    identifierType: string,
    identifierValue: string,
    interval = 0
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let intervalId;

      // Function to check for the element
      const checkElement = () => {
        const element = this.getElement(identifierType, identifierValue);

        if (element) {
          clearInterval(intervalId);
          resolve(element);
        }
      };

      // Initial check for the element
      checkElement();

      // Set up interval for polling (checks every 1000ms)
      intervalId = setInterval(checkElement, interval);
    });
  }

  // Function to get the DOM element based on identifier type and value
  private getElement(identifierType: string, identifierValue: string): any {
    switch (identifierType.toLowerCase()) {
      case "id":
        return document.getElementById(identifierValue);
      case "class":
        return document.getElementsByClassName(identifierValue)[0];
      case "tag":
        return document.getElementsByTagName(identifierValue)[0];
      case "name":
        return document.getElementsByName(identifierValue)[0];
      case "selector":
        return document.querySelector(identifierValue);
      default:
        throw new Error("Invalid identifier type");
    }
  }

  getQuestionnaireResultAnalytics() {
    this.spinner = true;
    this.crudService.getQuestionnaireResultAnalytics(this.videoId).subscribe(
      async (res: any) => {
        this.questionnaireResult = res.data;

        let chartId =
          this.questionnaireResult[0].questionnaireResults.questions.map(
            (question: any) => `chartForAnswer_${question._id}`
          );

        this.waitForElement("id", chartId[0])
          .then((element) => {
            // console.log("Element found:", element);
            this.questionnaireResult.forEach((questionnaire: any) => {
              this.createChart(questionnaire.questionnaireResults);
              this.spinner = false;
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
      (error) => {
        // console.log("error", error);
        this.spinner = false;
      }
    );
  }

  getAverageForMultipleChoice(
    questionTotalSubmitted: number,
    answerTotalSubmitted: number
  ): any {
    const value =
      (answerTotalSubmitted / questionTotalSubmitted) * 100 == 0
        ? 0
        : ((answerTotalSubmitted / questionTotalSubmitted) * 100).toFixed(2);
    return questionTotalSubmitted == 0 ? 0 : value;
  }

  getAverageForFreeForm(answers: number, totalSubmittedAnswers: number): any {
    const value =
      totalSubmittedAnswers === undefined || totalSubmittedAnswers === 0
        ? 0
        : ((answers / totalSubmittedAnswers) * 100).toFixed(2);

    return value;
  }
}
