import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from 'src/app/services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
  selector: 'forgetPassword',
  templateUrl: './forgetPassword.component.html',
  styleUrls: ['./forgetPassword.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public loginObject: any = { password: '', cPassword: '', spinner: false, intialToken: ''};
  public fieldPassTextType = false;
  public fieldCPassTextType = false;
  passwordPattern = /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
  passwordMismatch: any;

  constructor(private urlService: CrudService,
              private router: Router,
              private route: ActivatedRoute,
              private localStorageService: LocalStorageService,
              private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      this.loginObject.intialToken = params.token;
      if (!(!!params.token)) {
        this.router.navigate(['login'])
      } else {
        this.localStorageService.removeItem('anonymousToken')
        //this.loginObject.spinner = true;
        this.urlService.confirmToken(this.loginObject.intialToken).subscribe(
            success => {
          this.loginObject.intialToken = success.data.data.resetToken
          //this.loginObject.spinner = false;
        })
      }
    })
  }



  ngOnInit() {
    if (!!this.localStorageService.getItem('token')) {
      // this.router.navigate(['dashboard']);
      this.router.navigate(['profile']);
    }
  }

  /**
   * Change user login
   * @param id {any} user id
   */
  changeUserLogin(id: any) {
    if ($('#' + id).val() != '') {
      $('#' + id).siblings('.input-field').addClass('video_input_focus');
      $('#' + id).addClass('focusIn');
    } else {
      $('#' + id).siblings('.input-field').removeClass('video_input_focus');
    }

    if (id == 'user_cpassword') {
      if (this.loginObject.password !== this.loginObject.cPassword) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    }
  }


  /**
   * Makes a request for reseting the password
   */
   onSubmit() {
     this.loginObject.spinner = false;
     
     if (this.loginObject.password !== this.loginObject.cPassword) {
       this.passwordMismatch = true;
       this.loginObject.spinner = false;
       return;
     }

     this.passwordMismatch = false;

    const finalObj: any = {
      password: this.loginObject.password,
      resetToken: this.loginObject.intialToken
     }
     
    this.urlService.resetPassword(finalObj).subscribe(
      success => {
        this.toastr.success(success.message);
        this.loginObject.spinner = false;
        this.router.navigate(['/login']);
      })
  }

  /**
   * Show/Hide password for password field
   */
  togglePasswordTextType() {
    this.fieldPassTextType = !this.fieldPassTextType;
  }

  /**
   * Show/Hide password for confirm password field
   */
  toggleCPasswordTextType() {
    this.fieldCPassTextType = !this.fieldCPassTextType;
  }

}
